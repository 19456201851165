jQuery( document ).ready( function ( $ ) {
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();

		$.ajax( {
			url: 'https://subs.sonymusicfans.com/submit',
			method: 'POST',
			data: $( this ).serialize(),
			success: function ( data, textStatus, jqXHR ) {
				$( 'form' ).html(
					'<div class="form-thanks">Thanks for signing up!</div>'
				);
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );
} );
